// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-city-js": () => import("./../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-blog-template-js": () => import("./../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cursos-en-tu-ciudad-js": () => import("./../src/pages/cursos-en-tu-ciudad.js" /* webpackChunkName: "component---src-pages-cursos-en-tu-ciudad-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-cuidad-js": () => import("./../src/pages/info-cuidad.js" /* webpackChunkName: "component---src-pages-info-cuidad-js" */),
  "component---src-pages-inscripciones-js": () => import("./../src/pages/inscripciones.js" /* webpackChunkName: "component---src-pages-inscripciones-js" */),
  "component---src-pages-maestros-js": () => import("./../src/pages/maestros.js" /* webpackChunkName: "component---src-pages-maestros-js" */),
  "component---src-pages-muro-de-admitidos-js": () => import("./../src/pages/muro-de-admitidos.js" /* webpackChunkName: "component---src-pages-muro-de-admitidos-js" */),
  "component---src-pages-publicaciones-js": () => import("./../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */),
  "component---src-pages-tips-para-estudiar-js": () => import("./../src/pages/tips-para-estudiar.js" /* webpackChunkName: "component---src-pages-tips-para-estudiar-js" */)
}

